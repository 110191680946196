import { ILens } from "../hooks/useLense";
import { DependedOptionFieldValue, IDependentOptions, SimpleDependedOptionFieldValue } from "../types/api-data-types";

export type DependedOptionOp = "eq" | "neq";
export type DependedOptionOpComparator = (left: any, right: SimpleDependedOptionFieldValue) => boolean;

export const getDependedOptionOpComparator: Record<DependedOptionOp, DependedOptionOpComparator> = {
    "eq": (left, right) => left === right,
    "neq": (left, right) => left !== right,
};

export const baseDependedOptionOpComparator = (left: any, right: DependedOptionFieldValue, op: DependedOptionOp): boolean => {
    const comparator = getDependedOptionOpComparator[op];

    if (Array.isArray(right)) {
        if (typeof right[0] === 'string') {
            return (right as string[]).reduce((acc, value) => acc && comparator(left, value), true);
        }
        else {
            return (right as number[]).reduce((acc, value) => acc && comparator(left, value), true);
        }
    }

    return comparator(left, right);
};

export const isDisabledDependent = (lens: ILens<any>, options: IDependentOptions): boolean | undefined => {
    return options.isDisabled instanceof Object
        ? baseDependedOptionOpComparator((lens.prop(options.isDisabled.fieldName).get() as any), options.isDisabled.fieldValue, options.isDisabled.op)
        : options.isDisabled;
};

export const isHiddenDependent = (lens: ILens<any>, options: IDependentOptions): boolean | undefined => {
    return options.isHidden instanceof Object
        ? baseDependedOptionOpComparator((lens.prop(options.isHidden.fieldName).get() as any), options.isHidden.fieldValue, options.isHidden.op)
        : options.isHidden;
};
