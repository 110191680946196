import React from 'react';
import cn from 'classnames';

import { MultistringText } from '../common/multistring-text/multistring-text';
import { EventsProgramSectionData } from '../../types/api-data-types';
import { SectionComponentProps } from '../../types/component-types';
import { Section } from '../common/section/section';
import { Title } from '../common/title/title';
import { iconByName } from './helpers';

import css from './events-program.module.css';

const timeFormatOptions:Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: 'h23',
};

export const EventsProgram: React.FC<SectionComponentProps<EventsProgramSectionData>> = ({ data }) => {
    const { title, events, text } = data;
    return (
        <Section className={css['events-program']}>
            <Title text={title} />
            <div className={cn(css.timeline, { [css['expand-height']]: !(events.length % 2)})}>
                {events.map(({ icon, title, time }, idx) => {
                    const timeFrom = !!time.from && new Date(time.from).toLocaleTimeString(navigator.language, timeFormatOptions);
                    const timeTo = !!time.to && new Date(time.to).toLocaleTimeString(navigator.language, timeFormatOptions);
                    return (
                        <div key={idx} className={css['timeline-item']}>
                            <div className={css['timeline-item-wrapper']}>
                                <div className={css['icon-container']}>
                                    <img className={css.icon} src={iconByName[icon]} alt={`${icon} icon`} />
                                </div>
                                <h3 className={css.title}>{title}</h3>
                                <span className={css.time}>{`${timeFrom || ''}${timeTo ? `${timeFrom ? '- ' : ''}${timeTo}` : ''}`}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            {text && (<MultistringText className={css['additional-text']} text={text} />)}
        </Section>
    );
};
