import React from 'react';

import smallHeartImage from '../../assets/images/small_heart.svg';

import { SectionComponentProps } from '../../types/component-types';
import { HeaderSectionData } from '../../types/api-data-types';

import css from './header.module.css';

export const Header: React.FC<SectionComponentProps<HeaderSectionData>> = ({ data }) => {
    const { title, bridesName, groomsName } = data;
    return (
        <header className={css.header}>
            <h1 className={css.title}>{title}</h1>

            <div className={css['names-container']}>
                <div className={css.names}>
                    <span className={css.name}>{bridesName}</span>
                    <img src={smallHeartImage} width="15px" alt='small heart' />
                    <span className={css.name}>{groomsName}</span>
                </div>
            </div>
        </header>
    );
};
