import React from 'react';

import css from './not-found.module.css';

export const NotFound: React.FC = () => {
    return (
        <header className={css['not-found']}>
            <div>
                <div className={css['container']}>
                    <span className={css.code}>404</span>
                    <span className={css.message}>Страница не найдена</span>
                </div>
            </div>
        </header>
    );
};
