import React from 'react';
import { useLoaderData } from 'react-router-dom';

import { Header } from './components/header/header';
import { Invitation } from './components/invitation/invitation';
import { Calendar } from './components/calendar/calendar';
import { AdditionalInvitation } from './components/additional-invitation/additional-invitation';
import { EventsProgram } from './components/events-program/events-program';
import { CeremonyLocation } from './components/ceremony-location/ceremony-location';
import { ParticipationForm } from './components/participation-form/participation-form';
import { InvitationData, SectionType } from './types/api-data-types';
import { SectionComponentProps } from './types/component-types';

import css from './app.module.css';

const mapSectionNameToComponent: Record<SectionType, React.FC<SectionComponentProps<any>>> = {
  [SectionType.header]: Header,
  [SectionType.invitation]: Invitation,
  [SectionType.calendar]: Calendar,
  [SectionType.additionalInvitation]: AdditionalInvitation,
  [SectionType.eventsProgram]: EventsProgram,
  [SectionType.ceremonyLocation]: CeremonyLocation,
  [SectionType.participationForm]: ParticipationForm,
};

export const App:React.FC = () => {
  const { data } = useLoaderData() as { data: InvitationData };

  return (
    <div className={css.app}>
      {data.sections.map((section) => {
        const Component = mapSectionNameToComponent[section.name];
        return <Component key={section.name} data={section.data} />;
      })}
    </div>
  );
};
