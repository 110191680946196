import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Section } from '../section/section';

import css from './error-boundary.module.css';
import { MultistringText } from '../multistring-text/multistring-text';

export const ErrorBoundary: React.FC = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        const { status, statusText, data } = error;

        return (
            <Section className={css['error']}>
                <div className={css.background}>
                    <div className={css['container']}>
                        {!!status && (<span className={css.status}>{status}</span>)}
                        {!!statusText && (<span className={css['status-text']}>{statusText}</span>)}
                    </div>
                </div>
                {!!data?.message && (<p className={css.message}>{data.message}</p>)}
            </Section>
        );
    }

    return (
        <Section className={css['error']}>
            <div className={css.background}>
                <div className={css['container']}>
                    <span className={css['status-text']}>Упс...</span>
                </div>
            </div>
            <MultistringText text={[
                'Кажется что-то пошло не так,',
                'попробуйте обновить страницу позже',
            ]} />
        </Section>
    );
};
