import React, { FormEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import preloaderImage from '../../assets/icons/preloader.svg';

import { useLens } from '../../hooks/useLense';
import { SectionComponentProps } from '../../types/component-types';
import { FormData, ParticipationFormSectionData, ParticipationType } from '../../types/api-data-types';
import { useParticipationFormData } from '../../hooks/useParticipationFormData';
import { MultistringText } from '../common/multistring-text/multistring-text';
import { Section } from '../common/section/section';
import { Title } from '../common/title/title';
import { mapFormFieldTypeToComponent } from './helpers';

import css from './participation-form.module.css';

const getThankYouText = (participation: string): string => {
    if (participation === ParticipationType.no) {
        return "Очень жаль что вы не сможете прийти :(";
    }

    if (participation === ParticipationType.maybe) {
        return "Будем надеяться что у вас все же получиться прийти. :)";
    }

    return "Будем вас ждать! :)";
};

export const ParticipationForm: React.FC<SectionComponentProps<ParticipationFormSectionData>> = ({ data }) => {
    const { title, text, isAlreadyFilled, participation, form } = data;

    const { token } = useParams();
    const [formAlreadyFilled, setFormAlreadyFilled] = useState(isAlreadyFilled);
    const { isLoading, sendFormData } = useParticipationFormData(token ?? "");
    const [errors, setErrors] = useState<string[]>([]);

    const { lens } = useLens(form.reduce((acc, { name, defaultValue }) => {
        return {...acc, [name]: defaultValue};
    }, {}) as FormData);

    useEffect(() => {
        setErrors([]);
    }, [lens]);

    const handleSubmitForm: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        
        setErrors([]);

        try {
            const { data, error } = await sendFormData(lens.get() ?? {});

            if (error) {
                const fieldsNamesMap: Record<string, string> = form.reduce((acc, item) => ({ ...acc, [item.name]: item.options.label }), {});
                setErrors(error.map(({ message }) => {
                    return Object.keys(fieldsNamesMap).reduce((acc, key) => acc.replaceAll(key, fieldsNamesMap[key]), message);
                }));
            }

            if (data?.token === token) {
                setFormAlreadyFilled(true);
            }
        }
        catch (err) {
            // Nothing
        }
    };

    return (
        <Section className={css['participation-form-container']}>
            {formAlreadyFilled
                ? (
                    <Title text={getThankYouText(participation!)} />
                )
                : (
                    <>
                        <Title text={title} />

                        <MultistringText text={text} />

                        <form className={css.form} onSubmit={handleSubmitForm}>
                            {form.map((formField, idx) => {
                                const Component = mapFormFieldTypeToComponent[formField.type];
                                return (
                                    <Component key={idx} lens={lens} field={formField} />
                                );
                            })}
                            
                            {errors && (<MultistringText className={css.errors} text={errors} />)}

                            <div className={css['button-container']}>
                                <input
                                    className={css['submit-button']}
                                    type="submit"
                                    value="Отправить"
                                    disabled={isLoading}
                                />
                            </div>

                            {isLoading && (
                                <div className={css.loader}>
                                    <img src={preloaderImage} width={30} alt='preloader' />
                                </div>
                            )}
                        </form>
                    </>
                )
            }
        </Section>
    );
};
