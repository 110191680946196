export class InvitationDataRequestError extends Error {
  public static NOT_FOUND: string =
    "Кажется такого приглашения не существует ( Попробуйте обратиться за помощью к тому, у кого вы получили эту ссылку";

  constructor(public message: string, public status: number, public statusText: string) {
    super(message);
    this.name = "InvitationDataRequestError";
    this.stack = (new Error()).stack;
  }
}

export class UnexpectedInvitationDataRequestError extends Error {
    public static UNEXPECTED_MSG: string =
        "Кажется что-то пошло не так, попробуйте обновить страницу позже (";
  
    constructor(public message: string, public status: number, public statusText: string) {
      super(message);
      this.name = "UnexpectedInvitationDataRequestError";
      this.stack = (new Error()).stack;
    }
  }
