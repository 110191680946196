export const monthNamesInYear = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ];
export const dayNamesInWeek = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ];

export const padStart = (inputString: string, targetLength: number, padString: string) => {
    const padLength = targetLength - inputString.length;

    if (padLength === 0) {
        return inputString;
    }

    return `${padString.repeat(Math.ceil(padString.length / padLength)).slice(0, padLength)}${inputString}`;
};
