import React from 'react';

import { SectionComponentProps } from '../../types/component-types';
import { AdditionalInvitationSectionData } from '../../types/api-data-types';
import { Section } from '../common/section/section';
import { OpenMapButton } from '../common/open-map-button/open-map-button';
import { MultistringText } from '../common/multistring-text/multistring-text';

import css from './additional-invitation.module.css';

export const AdditionalInvitation: React.FC<SectionComponentProps<AdditionalInvitationSectionData>> = ({ data }) => {
    const { text, location } = data;
    return (
        <Section className={css['additional-invitation']}>
            <MultistringText text={text} />
            <OpenMapButton location={location} />
        </Section>
    );
};
