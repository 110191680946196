import { LoaderFunction, json } from "react-router-dom";

import { ApiResponse, InvitationData } from "../types/api-data-types";
import { InvitationDataRequestError, UnexpectedInvitationDataRequestError } from "../types/error-types";
// import { LocalStorageKey, useLocalStroage as UseLocalStorage } from "../hooks/useLocalStorage";

// import { mockData } from "./mockData";

export type MultilinesString = string[];

export const invitationDataLoader: LoaderFunction = async ({ params }): Promise<ApiResponse<InvitationData>> => {
    // const { getItem, setItem, removeItem } = UseLocalStorage();

    // return { data: mockData };

    const { token } = params;

    try {
        const response = await fetch(`/api/v1/invitation/${token}`, {});

        if (response.ok) {
            const apiResponseData = await response.json() as ApiResponse<InvitationData>;
            // token && setItem(LocalStorageKey.invitationToken, token);
            return apiResponseData;
        }

        // const storageToken = getItem(LocalStorageKey.invitationToken);
        // if (storageToken) {
        //     removeItem(LocalStorageKey.invitationToken);
        //     redirect(`/invitation/${storageToken}`);
        // }

        // Not found
        if (response.status === 404) {
            throw new InvitationDataRequestError(
                InvitationDataRequestError.NOT_FOUND,
                response.status,
                response.statusText,
            );
        }
        
        // Unexpected error
        throw new UnexpectedInvitationDataRequestError(
            UnexpectedInvitationDataRequestError.UNEXPECTED_MSG,
            response.status,
            response.statusText,
        );
    }
    catch (err: any) {
        if (err instanceof InvitationDataRequestError || err instanceof UnexpectedInvitationDataRequestError) {
            const { status, statusText, message } = err;
            throw json({ message }, { status, statusText });
        }

        throw json({
            message: "Кажется что-то пошло не так, попробуйте обновить страницу позже ("
        },
        {
            status: 401,
            statusText: "Упс...",
        });
    }
};