import { CheckboxDirection } from "../components/common/checkbox-group/checkbox-group";
import { RadioGroupItem } from "../components/common/radio-group/radio-group";
import { DependedOptionOp } from "../helpers/helpers";
import { MapLocation } from "./common-types";

export enum ApiErrorStatus {
    failed = 'failed',
    ok = 'ok',
}

export interface ApiError {
    path?: (string | number)[];
    message: string;
}

export interface ApiErrorResponse {
    status?: ApiErrorStatus;
    error?: ApiError[];
}

export interface ApiResponse<T> extends ApiErrorResponse {
    data?: T;
}

export interface ParticipantDTO {
    token: string;
    name: string;
    participation: string;
    drinkPreferences: string[];
}

// Sections data
export interface HeaderSectionData {
    title: string;
    bridesName: string;
    groomsName: string;
}

export interface InvitationSectionData {
    text: string[];
}

export interface CalendarSectionData {
    ceremonyDateString: string;
    partyDateString?: string;
    partyDayOnly: boolean;
}

export interface AdditionalInvitationSectionData {
    text: string[];
    location: MapLocation;
}

export interface EventProgram {
    icon: string,
    title: string,
    time: {
        from?: string;
        to?: string;
    },
}

export interface EventsProgramSectionData {
    title: string;
    events: EventProgram[];
    text?: string[];
}

export interface EventLocation {
    text: string[];
    location: MapLocation;
    photo?: string;
}

export interface CeremonyLocationSectionData {
    title: string;
    places: EventLocation[];
}

// Participation form

export enum FormFieldType {
    "inputText" = "inputText",
    "radioGroup" = "radioGroup",
    "checkboxGroup" = "checkboxGroup",
}

export enum ParticipationType {
    'yes' = 'yes',
    'yesPlusOne' = 'yes-plus-one',
    'maybe' = 'maybe',
    'no' = 'no',
}

export enum DrinkPreferenceType {
    champagne = "champagne",
    whiteWine = "white wine",
    redWine = "red wine",
    vodka = "vodka",
    cognac = "cognac",
    whiskey = "whiskey",
    beer = "beer",
    alcoholFree = "alcohol free",
}

export type SimpleDependedOptionFieldValue = string | number | boolean;
export type ArrayDependedOptionFieldValue = string[] | number[];
export type DependedOptionFieldValue = SimpleDependedOptionFieldValue | ArrayDependedOptionFieldValue;

export type DependedOption = {
    fieldName: string;
    op: DependedOptionOp;
    fieldValue: DependedOptionFieldValue;
};

export interface IDependentOptions {
    isDisabled?: DependedOption | boolean;
    isHidden?: DependedOption | boolean;
}

export interface TextInputFormFieldOptions extends IDependentOptions {
    label?: string;
    placeholder?: string;
}

export interface TextInputFormField {
    type: FormFieldType.inputText;
    name: string;
    defaultValue: string;
    options: TextInputFormFieldOptions,
}

export interface RadioGroupFormFieldOptions extends IDependentOptions {
    items: RadioGroupItem<string>[];
    label?: string;
    direction?: CheckboxDirection;
}

export interface RadioGroupFormField {
    type: FormFieldType.radioGroup;
    name: string;
    defaultValue: string;
    options: RadioGroupFormFieldOptions,
}

export interface CheckboxGroupFormFieldOptions extends IDependentOptions {
    items: RadioGroupItem<string>[];
    label?: string;
    direction?: CheckboxDirection;
}

export interface CheckboxGroupFormField {
    type: FormFieldType.checkboxGroup;
    name: string;
    defaultValue: string[];
    options: CheckboxGroupFormFieldOptions,
}

export type FormFields = TextInputFormField | RadioGroupFormField | CheckboxGroupFormField;

export type FormData = Record<string, Pick<TextInputFormField, 'defaultValue'> | Pick<RadioGroupFormField, 'defaultValue'> | Pick<CheckboxGroupFormField, 'defaultValue'>>;

export interface ParticipationFormSectionData {
    title: string;
    text: string[];
    isAlreadyFilled: boolean;
    participation?: string;
    form: FormFields[],
}

// Sections
export enum SectionType {
    "header" = "header",
    "invitation" = "invitation",
    "calendar" = "calendar",
    "additionalInvitation" = "additionalInvitation",
    "eventsProgram" = "eventsProgram",
    "ceremonyLocation" = "ceremonyLocation",
    "participationForm" = "participationForm",
}

export interface HeaderSection {
    name: SectionType.header;
    data: HeaderSectionData;
}

export interface InvitationSection {
    name: SectionType.invitation;
    data: InvitationSectionData;
}

export interface CalendarSection {
    name: SectionType.calendar;
    data: CalendarSectionData;
}

export interface AdditionalInvitationSection {
    name: SectionType.additionalInvitation;
    data: AdditionalInvitationSectionData;
}

export interface EventsProgramSection {
    name: SectionType.eventsProgram;
    data: EventsProgramSectionData;
}

export interface CeremonyLocationSection {
    name: SectionType.ceremonyLocation;
    data: CeremonyLocationSectionData;
}

export interface ParticipationFormSection {
    name: SectionType.participationForm;
    data: ParticipationFormSectionData;
}

export type Section =
    HeaderSection |
    InvitationSection |
    CalendarSection |
    AdditionalInvitationSection |
    EventsProgramSection |
    CeremonyLocationSection |
    ParticipationFormSection;

export interface InvitationData {
    sections: Section[];
}
