import React from 'react';
import cn from 'classnames';

import { IDisableable, IEditable, ILens } from '../../../hooks/useLense';
import { FormFieldWrapperProps } from '../../../types/component-types';
import { FormData, TextInputFormField } from '../../../types/api-data-types';
import { isDisabledDependent, isHiddenDependent } from '../../../helpers/helpers';
import { PropsWithClassName } from '../types';

import css from './text-input.module.css';

export interface TextInputProps<T> extends IEditable<T>, IDisableable, PropsWithClassName {
    name?: string;
    label?: string;
    placeholder?: string;
    isHidden?: boolean;
}

export const TextInput: React.FC<TextInputProps<string>> = ({ className, name, label, placeholder, isDisabled, isHidden, value, onValueChange }) => {
    return (
        <div className={cn(css['text-input'], className, { [css.hidden]: isHidden })}>
            <label>
                {label}
                <input
                    className={css['name-form-field']}
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={(event) => onValueChange(event.target.value)}
                    disabled={isDisabled}
                />
            </label>
        </div>
    );
};

export interface TextInputWrapperProps extends FormFieldWrapperProps<TextInputFormField, ILens<FormData>>, PropsWithClassName {}

export const TextInputWrapper: React.FC<TextInputWrapperProps> = ({ className, lens, field }) => {
    const { name, options } = field;
    const isHidden = isHiddenDependent(lens, options);
    return (
        <div className={cn(css['text-input-wrapper'], { [css.hidden]: isHidden})}>
            <p className="label">{options.label}</p>

            <TextInput
                className={className}
                name={name}
                placeholder={options.placeholder}
                {...((lens.prop(name).toProps() as any) as IEditable<string>)}
                isDisabled={isDisabledDependent(lens, options)}
            />
        </div>
    );
};