import ceremonyIcon from '../../assets/icons/ceremony-icon.svg';
import photoIcon from '../../assets/icons/photo-icon.svg';
import welcomeToastIcon from '../../assets/icons/welcome-toast-icon.svg';
import dinnerIcon from '../../assets/icons/dinner-icon.svg';
import danceIcon from '../../assets/icons/dance-icon.svg';
import cakeCuttingIcon from '../../assets/icons/cake-cutting-icon.svg';
import grandExitIcon from '../../assets/icons/grand-exit-icon.svg';
import startIcon from '../../assets/icons/start-icon.svg';
import freeTimeIcon from '../../assets/icons/free-time-icon.svg';
import contestIcon from '../../assets/icons/contest-icon.svg';
import talkingIcon from '../../assets/icons/talking-icon.svg';
import karaokeIcon from '../../assets/icons/karaoke-icon.svg';

export const iconByName: Record<string, string> = {
    ceremony: ceremonyIcon,
    photo: photoIcon,
    toasts: welcomeToastIcon,
    dinner: dinnerIcon,
    dance: danceIcon,
    cake: cakeCuttingIcon,
    exit: grandExitIcon,
    start: startIcon,
    freetime: freeTimeIcon,
    contests: contestIcon,
    talking: talkingIcon,
    karaoke: karaokeIcon,
};
