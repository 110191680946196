import React from 'react';
import cn from 'classnames';

import { PropsWithClassName } from '../types';

import css from './multistring-text.module.css';

export interface MultistringTextProps extends PropsWithClassName {
    text: string[];
}

export const MultistringText: React.FC<MultistringTextProps> = ({ className, text }) => {
    return (
        <div className={cn(css['text-group'], className)}>
            {text.map((line, idx) => <p key={idx} className={css.line}>{line}</p>)}
        </div>
    );
};
