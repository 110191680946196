import React from 'react';

import goldenRingsImage from '../../assets/images/gold_rings.png';

import { Section } from '../common/section/section';
import { MultistringText } from '../common/multistring-text/multistring-text';
import { SectionComponentProps } from '../../types/component-types';
import { InvitationSectionData } from '../../types/api-data-types';

import css from './invitation.module.css';

export const Invitation: React.FC<SectionComponentProps<InvitationSectionData>> = ({ data }) => {
    const { text } = data;
    return (
        <Section className={css.invitation}>
            <img className={css.image} src={goldenRingsImage} alt="rings" />
            <MultistringText text={text} />
        </Section>
    );
};
