import { useMemo, useState } from "react";

type ArrayElement<ArrayType> = ArrayType extends (infer ElementType)[] ? ElementType : never;

export interface IControlled<T> {
    value: T;
    onValueChange(newValue: T): void;
}

export interface IDisableable {
    isDisabled?: boolean;
}

export interface IEditable<T> extends IControlled<T> {}

export interface ILens<TFocused> {
    get(): TFocused | null;
    set(value: TFocused): void;
    // update(fn: (current: TFocused) => TFocused): void;
    prop<K extends keyof TFocused>(name: K): ILens<NonNullable<TFocused[K]>>;
    index(index: number): ILens<ArrayElement<TFocused>>;
    // onChange(fn: (oldValue: TFocused, newValue: TFocused) => TFocused): ILens<TFocused>;
    toProps(): IEditable<TFocused>;
}

interface ILensImpl<TBig, TSmall> {
    get(big: TBig | null): TSmall | null;
    set(big: TBig | null, small: TSmall): TBig;
}

const identityLens: ILensImpl<any, any> = {
    get(big) {
        return big;
    },
    set(big, small) {
        return small;
    },
};

const prop = <TObject, TKey extends keyof TObject>(name: TKey): ILensImpl<TObject, TObject[TKey]> => {
    return {
        get(big) {
            if (big == null) {
                return null;
            }
            else {
                return big[name];
            }
        },
        set(big, small) {
            const newObject = Object.assign(Object.assign({}, big), { [name]: small });
            return newObject;
        },
    };
}

const index = <TItem>(num: number): ILensImpl<TItem[], TItem> => {
    return {
        get(big) {
            if (big == null) {
                return null;
            }
            else {
                return big[num];
            }
        },
        set(big, small) {
            const newArray = [...(big ?? [])];
            newArray[num] = small;
            return newArray;
        },
    };
}

const compose = <TBig, TMiddle, TSmall>(left: ILensImpl<TBig, TMiddle>, right: ILensImpl<TMiddle, TSmall>): ILensImpl<TBig, TSmall> => {
    if (left === identityLens) {
        return (right as any) as ILensImpl<TBig, TSmall>;
    }

    if (right === identityLens) {
        return (left as any) as ILensImpl<TBig, TSmall>;
    }

    return {
        get(big) {
            const middle = left.get(big);
            const small = right.get(middle);
            return small;
        },
        set(big, small) {
            let middle = left.get(big);
            middle = right.set(middle, small);
            return left.set(big, middle);
        },
    };
}

class LensBuilder<TRoot = any, TFocused = any> implements ILens<TFocused> {
    readonly lens: ILensImpl<TRoot, TFocused>;
    readonly handleValueChange: (newValue: TFocused) => void;

    constructor(lens: ILensImpl<TRoot, TFocused>) {
        this.lens = lens;
        this.handleValueChange = (newValue) => {
            this.lens.set(null, newValue);
        };
    }

    get() {
        return this.lens.get(null);
    }

    set(value: TFocused): void {
        this.lens.set(null, value);
    }

    // update(fn: (current: TFocused) => TFocused): void {
    //     this.lens.set(null, fn(this.lens.get(null)));
    // }

    compose<TSmall>(lens: ILensImpl<TFocused, TSmall>): LensBuilder<TRoot, TSmall> {
        return new LensBuilder(compose(this.lens, lens));
    }

    prop<K extends keyof TFocused>(name: K) {
        return (this.compose(prop(name)) as any) as ILens<NonNullable<TFocused[K]>>;
    }

    index(index$1: number) {
        return (this.compose((index(index$1) as any) as ILensImpl<TFocused, ArrayElement<TFocused>>) as any) as ILens<ArrayElement<TFocused>>;
    }

    // onChange(fn: (oldValue: TFocused, newValue: TFocused) => TFocused): LensBuilder<TRoot, TFocused> {
    //     return this.compose({
    //         get: (i) => i, set: fn, getValidationState: this.lens.getValidationState, getMetadata: this.lens.getMetadata,
    //     }, fn);
    // }

    toProps() {
        return { value: this.lens.get(null), onValueChange: this.handleValueChange } as IEditable<TFocused>;
    }
}

export const useLens = <T>(initialValue: T) => {
    const [value, setValue] = useState(initialValue);

    const lens = useMemo(() => new LensBuilder({
        get: () => value,
        set: (_, small) => setValue(small),
    }), [value]);

    return {
        lens,
    };
};
