import React from 'react';

import css from './title.module.css';

export interface TitleProps {
    text: string;
}

export const Title: React.FC<TitleProps> = ({ text }) => {
    return (
        <h2 className={css.title}>{text}</h2>
    );
};