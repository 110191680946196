import React from 'react';
import cn from 'classnames';

import { IDisableable, IEditable, ILens } from '../../../hooks/useLense';
import { FormFieldWrapperProps } from '../../../types/component-types';
import { CheckboxGroupFormField, FormData } from '../../../types/api-data-types';
import { isDisabledDependent } from '../../../helpers/helpers';
import { PropsWithClassName } from '../types';

import css from './checkbox-group.module.css';

export type CheckboxDirection = 'vertical' | 'horizontal';

export interface CheckboxGroupItem<TValue> extends IDisableable {
    name?: string;
    id: TValue;
}

export interface CheckboxGroupProps<TValue> extends IEditable<TValue[]>, IDisableable, PropsWithClassName {
    items: CheckboxGroupItem<TValue>[];
    direction?: CheckboxDirection;
}

export function CheckboxGroup<TValue>(props: CheckboxGroupProps<TValue>): JSX.Element {
    const { className, items, direction = 'vertical', isDisabled, value, onValueChange } = props;
    
    return (
        <div className={cn(css['checkbox-group'], className, css[direction])}>
            {items.map((item, idx) => (
                <label key={idx} className={cn({ [css.disabled]: isDisabled || item.isDisabled })}>
                    <input
                        type="checkbox"
                        name={String(item.id)}
                        onChange={(event) => {
                            const isChecked = event.target.checked;
                            onValueChange(isChecked ? [...value, item.id] : value.filter((v) => v !== item.id));
                        }}
                        disabled={isDisabled || item.isDisabled}
                        checked={value.includes(item.id)}
                    />
                    {item.name}
                </label>
            ))}
        </div>
    );
};

export interface CheckboxGroupWrapperProps extends FormFieldWrapperProps<CheckboxGroupFormField, ILens<FormData>>, PropsWithClassName {}

export const CheckboxGroupWrapper: React.FC<CheckboxGroupWrapperProps> = ({ className, lens, field }) => {
    const { name, options } = field;
    return (
        <div className={css['checkbox-group-wrapper']}>
            <p className="label">{options.label}</p>

            <CheckboxGroup
                className={className}
                items={options.items}
                {...((lens.prop(name).toProps() as any) as IEditable<string[]>)}
                direction="vertical"
                isDisabled={isDisabledDependent(lens, options)}
            />
        </div>
    );
};
