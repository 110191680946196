import  React from 'react';
import cn from 'classnames';

import smallBranchImage from '../../assets/images/small_branch.svg';

import { SectionComponentProps } from '../../types/component-types';
import { CalendarSectionData } from '../../types/api-data-types';
import { dayNamesInWeek, monthNamesInYear, padStart } from './helpers';

import css from './calendar.module.css';

const isEqDates = (left: Date, right: Date) => left.getFullYear() === right.getFullYear() && left.getMonth() === right.getMonth() && left.getDate() === right.getDate();

export const Calendar: React.FC<SectionComponentProps<CalendarSectionData>> = ({ data }) => {
    const { ceremonyDateString, partyDateString, partyDayOnly } = data;

    const nowDate = new Date();
    const ceremonyDate = new Date(ceremonyDateString);
    const partyDate = !!partyDateString && new Date(partyDateString);
    const calendarDate = (ceremonyDate || partyDate) as Date;

    const countDaysInMonth = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 0).getDate();
    const startMonthDay = ((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), 1).getDay()) + 6) % 7;

    const calendarMonth = calendarDate.getMonth();

    return (
        <div className={css['calendar-container']}>
            <h2 className={css.title}>
                <span>{monthNamesInYear[calendarMonth]}</span>
                <img src={smallBranchImage} width="14px" alt="small branch" />
                <span>{calendarDate.getFullYear()}</span>
            </h2>

            <div className={css.calendar}>
                <div className={css.header}>
                    {dayNamesInWeek.map((day) => (
                        <span key={day} className={css.day}>{day}</span>
                    ))}
                </div>

                <div className={css.body}>
                    {Array(startMonthDay).fill(null).map((_, idx) => <span key={idx} className={css.day} />)}
                    {Array(countDaysInMonth).fill(null).map((_, idx) => {
                        const monthDate = new Date(calendarDate);
                        monthDate.setDate(idx + 1);
                        const monthDay = monthDate.getDate();
                        return (
                            <span
                                key={idx + startMonthDay}
                                className={cn(css.day, {
                                    [css['now-day']]: isEqDates(monthDate, nowDate),
                                    [css['ceremony-day']]: isEqDates(monthDate, ceremonyDate),
                                    [css['ceremony-day-icon']]: !partyDayOnly && isEqDates(monthDate, ceremonyDate),
                                    [css['party-day-icon']]: partyDate && isEqDates(monthDate, partyDate),
                                })}
                            >
                                {monthDay > 0 ? padStart(String(monthDay), 2, '0') : ''}
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
