import React from 'react';

import { MapLocation } from '../../../types/common-types';

import css from './open-map-button.module.css';

export interface OpenMapButtonProps {
    location: MapLocation;
}

export const OpenMapButton: React.FC<OpenMapButtonProps> = ({ location }) => {
    const { latitude, longitude } = location;
    return (
        <a className={css['map-button']} href={`geo:${latitude},${longitude}`} target="_blank" rel="noreferrer">Перейти на карту</a>
    );
};
