import React from 'react';
import cn from 'classnames';

import { IDisableable, IEditable, ILens } from '../../../hooks/useLense';
import { FormFieldWrapperProps } from '../../../types/component-types';
import { FormData, RadioGroupFormField } from '../../../types/api-data-types';
import { isDisabledDependent } from '../../../helpers/helpers';
import { PropsWithClassName } from '../types';

import css from './radio-group.module.css';

export interface RadioGroupItem<TValue> extends IDisableable {
    name?: string;
    id: TValue;
}

export interface RadioGroupProps<TValue> extends IEditable<TValue>, IDisableable, PropsWithClassName {
    name: string;
    items: RadioGroupItem<TValue>[];
    direction?: 'vertical' | 'horizontal';
}

export function RadioGroup<TValue>(props: RadioGroupProps<TValue>): JSX.Element {
    const { className, name, items, direction = 'vertical', isDisabled, value, onValueChange } = props;

    return (
        <div className={cn(css['radio-group'], className, css[direction])}>
            {items.map((item, idx) => (
                <label key={idx} className={cn({ [css.disabled]: item.isDisabled })}>
                    <input
                        type="radio"
                        name={name}
                        value={String(item.id)}
                        onChange={() => onValueChange(item.id)}
                        disabled={isDisabled || item.isDisabled}
                        // eslint-disable-next-line eqeqeq
                        checked={value == item.id}
                    />
                    {item.name}
                </label>
            ))}
        </div>
    );
};

export interface RadioGroupWrapperProps extends FormFieldWrapperProps<RadioGroupFormField, ILens<FormData>>, PropsWithClassName {}

export const RadioGroupWrapper: React.FC<RadioGroupWrapperProps> = ({ className, lens, field }) => {
    const { name, options } = field;
    return (
        <div className={css['radio-group-wrapper']}>
            <p className="label">{options.label}</p>

            <RadioGroup
                className={className}
                name={name}
                items={options.items}
                {...((lens.prop(name).toProps() as any) as IEditable<string>)}
                direction={options.direction}
                isDisabled={isDisabledDependent(lens, options)}
            />
        </div>
    );
};
