import { FormFieldType } from "../../types/api-data-types";
import { FormFieldWrapperProps } from "../../types/component-types";
import { CheckboxGroupWrapper } from "../common/checkbox-group/checkbox-group";
import { RadioGroupWrapper } from "../common/radio-group/radio-group";
import { TextInputWrapper } from "../common/text-input/text-input";

export const mapFormFieldTypeToComponent: Record<FormFieldType, React.FC<FormFieldWrapperProps<any, any>>> = {
    [FormFieldType.inputText]: TextInputWrapper,
    [FormFieldType.radioGroup]: RadioGroupWrapper,
    [FormFieldType.checkboxGroup]: CheckboxGroupWrapper,
};