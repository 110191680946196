import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import { PropsWithClassName } from '../types';

import css from './section.module.css';

export interface SectionProps extends PropsWithChildren, PropsWithClassName {
    withPaddings?: boolean;
}

export const Section: React.FC<SectionProps> = ({ children, className, withPaddings = true }) => {
    return (
        <div className={cn(css.section, className, { [css['with-paddings']]: withPaddings })}>
            {children}
        </div>
    );
};