import { useState } from "react";
import { json } from "react-router-dom";

import { ApiResponse, FormData, ParticipantDTO } from "../types/api-data-types";

export const useParticipationFormData = (token: string) => {
    const [isLoading, setIsLoading] = useState(false);

    const sendFormData = async (formData: FormData) => {
        setIsLoading(true);

        try {
            const response = await fetch(`/api/v1/participant/${token}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(formData),
            });

            if (response.ok || response.status === 400) {
                const participantApiResponse = await response.json() as ApiResponse<ParticipantDTO>;

                setIsLoading(false);

                return participantApiResponse;
            }

            throw new Error(`Ошибка HTTP: ${response.status}`);
        }
        catch (err: any) {
            setIsLoading(false);

            throw json({
                message: "Кажется что-то пошло не так, попробуйте обновить страницу позже ("
            },
            {
                status: 401,
                statusText: "Упс...",
            });
        }
    };

    return {
        isLoading,
        sendFormData,
    };
};
