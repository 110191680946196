import React from 'react';

import { SectionComponentProps } from '../../types/component-types';
import { CeremonyLocationSectionData } from '../../types/api-data-types';
import { Section } from '../common/section/section';
import { Title } from '../common/title/title';
import { MultistringText } from '../common/multistring-text/multistring-text';
import { OpenMapButton } from '../common/open-map-button/open-map-button';

import css from './ceremony-location.module.css';

export const CeremonyLocation: React.FC<SectionComponentProps<CeremonyLocationSectionData>> = ({ data }) => {
    const { title, places } = data;
    return (
        <div className={css['ceremony-location']}>
            <Title text={title} />
            <div className={css.places}>
                {places.map(({ text, location, photo }, idx) => (
                    <div key={idx} className={css['flex-container']}>
                        <Section className={css['flex-container']}>
                            <MultistringText text={text} />
                            <OpenMapButton location={location} />
                        </Section>
                        {!!photo && (<img src={photo} alt="guest house" />)}
                    </div>
                ))}
            </div>
        </div>
    );
};
